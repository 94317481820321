import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import {EmptyState, getMatchesCountText, paginationLabels} from './table-config';


export default function DriveVisitSummaryTable({logEntries, columnDefinitions, title}) {
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        logEntries,
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={<></>}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const { selectedItems } = collectionProps;
    return (
        <Table
            {...collectionProps}
            loading={!logEntries}
            loadingText="Loading instances"
            resizableColumns={true}
            wrapLines={true}
            // selectionType="single"
            header={
                <Header
                    counter={
                        logEntries &&
                        (selectedItems!.length ? `(${selectedItems!.length}/${logEntries.length})` : `(${logEntries.length})`)
                    }
                    actions={<></>}
                >
                    {title}
                </Header>
            }
            columnDefinitions={columnDefinitions}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
        />
    );
}
