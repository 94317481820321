import {DateRangePickerProps} from "@amzn/awsui-components-react";
import {DriveVisitSummary, LogTable} from "../../pets-api/generated-src";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import DriveVisitSummaryTable from "../table/DriveVisitSummaryTable";
import {COLUMN_DEFINITIONS} from "../table/table-config";

export interface DriveVisitSummariesProps {
    driveVisitSummaries: DriveVisitSummary[]
}

export function DriveVisitSummaries(props: DriveVisitSummariesProps) {
    const driveVisitSummaries = props.driveVisitSummaries;
    return <SpaceBetween size="l">
        {driveVisitSummaries?.map((dvs, i) => {
            const dvi = dvs.drive_visit_info;
            const {schema, data}: LogTable = JSON.parse(dvs.log_table);
            const titleString = `${dvi.transportType} at ${dvi.cellId} from ${dvi.arrivalTime} to ${dvi.releaseTime}`
            return <div key={i}>
                <DriveVisitSummaryTable logEntries={data} columnDefinitions={COLUMN_DEFINITIONS} title={titleString}/>
            </div>
        })}
    </SpaceBetween>
}

export function getStartEndDate(range: DateRangePickerProps.Value) {
    let endDate = new Date();
    let startDate = new Date();
    //
    switch (range.type) {
        case "relative":
            switch (range.unit) {
                case "second":
                    startDate.setSeconds(endDate.getSeconds() - range.amount);
                    break;
                case "minute":
                    startDate.setMinutes(endDate.getMinutes() - range.amount);
                    break;
                case "hour":
                    startDate.setHours(endDate.getHours() - range.amount);
                    break;
                case "day":
                    startDate.setDate(endDate.getDate() - range.amount);
                    break;
                case "week":
                    startDate.setDate(endDate.getDate() - (range.amount * 7));
                    break;
                case "month":
                    startDate.setMonth(endDate.getMonth() - range.amount);
                    break;
                case "year":
                    startDate.setDate(endDate.getDate() - (range.amount * 365));
                    break;
            }
            break;
        case "absolute":
            startDate = new Date(range.startDate);
            endDate = new Date(range.endDate);
    }
    return {startDate, endDate};
}