import {Route, Routes} from 'react-router-dom';
import Homepage, {Layout} from './home';
import CommonHeader from './CommonHeader';
import FormPage from './form/FormPage';
import {ErrorBoundary} from "react-error-boundary";
import PodVisitFinder from "./home/HomepageContent";

export function ErrorFallback({error, resetErrorBoundary}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

export default function App() {
  return (
      <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
              // reset the state of your app so the error doesn't happen again
          }}
      >
          <div>
              <CommonHeader/>
              <Layout>
                  <Routes>
                      <Route path="/" element={<Homepage/>}/>
                      <Route path="/form/:mode/:id" element={<FormPage/>}/>
                      <Route path="/podvisitfinder" element={<PodVisitFinder/>}/>
                  </Routes>
              </Layout>
          </div>
      </ErrorBoundary>
  );
}
