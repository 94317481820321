import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import './styles.scss';
import React, {useState} from "react";
import {Box, SideNavigation, SideNavigationProps} from "@amzn/awsui-components-react";
import { useHref, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const navHeader = {text: 'TasrsConsole', href: '#/'};
export const navItems: SideNavigationProps.Link[] = [
    {type: 'link', text: 'Home', href: '/'},
    {type: 'link', text: 'Pod Visit Finder', href: '/podvisitfinder'},
    {type: 'link', text: 'Monitoring and alarms', href: '/monitoring'},
    {type: 'link', text: 'Work Request Diver', href: '/workrequest/diver'},
];

export function Navigation({
                               header = navHeader,
                               items = navItems,
                           }) {
    const location = useLocation();
    const navigate = useNavigate();

    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        event.preventDefault();
        if (event.detail.href) {
            navigate(event.detail.href);
        }
    };
    return <SideNavigation items={items} header={header} activeHref={`${location.pathname}`} onFollow={onFollowHandler}/>;
}

export function Layout(props: { children?: React.ReactNode }) {
    const [navigationOpen, setNavigationOpen] = useState(false);
    return (
        <AppLayout
            content={props.children}
            contentType="default"
            navigation={<Navigation />}
            navigationOpen={navigationOpen}
            onNavigationChange={({detail}) => setNavigationOpen(detail.open)}
            toolsHide={true}
            disableContentPaddings={true}
        />
    );
}

export default function Homepage() {
    return <>
    </>;
}
