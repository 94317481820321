import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import {useMemo, useState} from "react";
import {DriveVisitSummaries, getStartEndDate} from "./DriveVisitSummaries";
import {PodVisitFinderForm, PodVisitFinderFormData} from "./PodVisitFinderForm";
import PodVisitsApiFactory from "../../pets-api/PodVisitsApiFactory";
import {DriveVisitSummary} from "../../pets-api/generated-src";
import {AxiosResponse} from "axios";
import {useSearchParams} from 'react-router-dom';

function serializeFormData(formData: PodVisitFinderFormData): Record<string, string> {
    const dateRange =  encodeURI(JSON.stringify(formData.dateRange));
    return {'toteId': formData.toteId, 'dateRange' : dateRange};
}


export default function PodVisitFinder() {
    // TODO make this selectable OR assume from site value
    const logGroups = ["TasrsOptimus/Prod/NA/HOU6/North/application.log",
        "TasrsOptimus/Prod/NA/HOU6/South/application.log",
        "TasrsOptimusOneBox/Prod/NA/HOU6/application.log"];
    // Used for development testing
    /*
    const toteId = 'hcX00en27ej';
    const start_time = "2022-12-08 00:00:00Z";
    const end_time = "2022-12-08 8:00:00Z";
    const testFormData: PodVisitFinderFormData = {
        toteId: toteId,
        dateRange: {startDate: new Date().toISOString(), endDate: new Date().toISOString(), type: "absolute"}
    }
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const toteId = searchParams.get('toteId');

    const dateRange = searchParams.get('dateRange');
    const hasSearchParams = toteId != null && dateRange != null;
    const [isLoading, setLoading] = useState(hasSearchParams);
    const [driveVisitSummaries, setDriveVisitSummaries] = useState<DriveVisitSummary[]>();
    const PodVisitsApi = PodVisitsApiFactory();

    // TODO: This should be useEffect probably
    useMemo(() => {
        if (toteId && dateRange) {
            (async () => {
                const {startDate, endDate} = getStartEndDate(JSON.parse(decodeURI(dateRange)));
                const response = await PodVisitsApi.getPodVistsForTote(toteId, startDate.toISOString(), endDate.toISOString(), logGroups);
                setLoading(false);
                setDriveVisitSummaries(response.data);
            })();
        }
    }, [searchParams]);
    const onSubmit = (formData: PodVisitFinderFormData) => {
        setLoading(true);
        setSearchParams(serializeFormData(formData));
        (async () => {
            const {startDate, endDate} = getStartEndDate(formData.dateRange);
            const response: AxiosResponse<Array<DriveVisitSummary>> = await PodVisitsApi.getPodVistsForTote(formData.toteId, startDate.toISOString(), endDate.toISOString(), logGroups);
            setLoading(false);
            setDriveVisitSummaries(response.data);
        })();
    };


    return (
        <Box padding={{top: 'xxl', horizontal: 's', bottom: 'l'}}>
            <Grid
                gridDefinition={[
                    {colspan: {xl: 10, l: 9, s: 10, xxs: 10}, offset: {l: 2, xxs: 1}}
                ]}
            >
                <SpaceBetween size="l">
                    <Header>Pod Visit Finder</Header>
                    <PodVisitFinderForm onSubmit={onSubmit} loading={isLoading} />
                    {driveVisitSummaries &&
                    <DriveVisitSummaries driveVisitSummaries={driveVisitSummaries}/>
                    }
                </SpaceBetween>
            </Grid>
        </Box>
    );
}
