import {useState} from "react";
import {Container, DateRangePicker, DateRangePickerProps, Form, FormField, Input} from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Box from "@amzn/awsui-components-react/polaris/box";
import {useSearchParams} from "react-router-dom";

const isValidRange: DateRangePickerProps.ValidationFunction = (range) => {
    // TODO: Add validation
    return {valid: true};
}

export interface PodVisitFinderFormData {
    toteId: string
    dateRange: DateRangePickerProps.Value
    // TODO: Add
    // loggroups: Array<string>
}

export function PodVisitFinderForm({onSubmit, loading}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const dateRangeSearchParam = searchParams.get("dateRange");
    const initialData: PodVisitFinderFormData = {
        toteId: searchParams.get('toteId') ?? "",
        dateRange: dateRangeSearchParam ? JSON.parse(decodeURI(dateRangeSearchParam)) : {
            key: "previous-6-hours",
            amount: 6,
            unit: "hour",
            type: "relative"
        }
    };
    const [formData, setFormData] = useState(initialData);
    const onChange = (attribute, value) => {
        const newState = {...formData};
        newState[attribute] = value;
        setFormData(newState);
    };
    return <Container>
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" disabled={loading} loading={loading}
                                onClick={() => onSubmit(formData)}>Submit</Button>
                    </SpaceBetween>
                }
                header={<><Header variant="h1">Find pod visits</Header><Box variant="p" fontWeight="light">Find all the pod visits
                    for a given tote in a time range. Use this information to determine what happened to a tote.</Box></>}>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Tote Id" stretch>
                        <Input value={formData.toteId}
                               ariaRequired={true}
                               placeholder="Tote Id"
                               onChange={({detail: {value}}) => onChange('toteId', value)}/>
                    </FormField>
                    <FormField>
                        <DateRangePicker
                            onChange={({detail: {value}}) => onChange('dateRange', value)}
                            value={formData.dateRange}
                            relativeOptions={[
                                {
                                    key: "previous-5-minutes",
                                    amount: 5,
                                    unit: "minute",
                                    type: "relative"
                                },
                                {
                                    key: "previous-30-minutes",
                                    amount: 30,
                                    unit: "minute",
                                    type: "relative"
                                },
                                {
                                    key: "previous-1-hour",
                                    amount: 1,
                                    unit: "hour",
                                    type: "relative"
                                },
                                {
                                    key: "previous-6-hours",
                                    amount: 6,
                                    unit: "hour",
                                    type: "relative"
                                }
                            ]}
                            isValidRange={isValidRange}
                            i18nStrings={{
                                todayAriaLabel: "Today",
                                nextMonthAriaLabel: "Next month",
                                previousMonthAriaLabel: "Previous month",
                                customRelativeRangeDurationLabel: "Duration",
                                customRelativeRangeDurationPlaceholder:
                                    "Enter duration",
                                customRelativeRangeOptionLabel: "Custom range",
                                customRelativeRangeOptionDescription:
                                    "Set a custom range in the past",
                                customRelativeRangeUnitLabel: "Unit of time",
                                formatRelativeRange: e => {
                                    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                    return `Last ${e.amount} ${t}`;
                                },
                                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                dateTimeConstraintText:
                                    "Range is 6 to 30 days. For date, use YYYY/MM/DD. For time, use 24 hr format.",
                                relativeModeTitle: "Relative range",
                                absoluteModeTitle: "Absolute range",
                                relativeRangeSelectionHeading: "Choose a range",
                                startDateLabel: "Start date",
                                endDateLabel: "End date",
                                startTimeLabel: "Start time",
                                endTimeLabel: "End time",
                                clearButtonLabel: "Clear and dismiss",
                                cancelButtonLabel: "Cancel",
                                applyButtonLabel: "Apply"
                            }}
                            placeholder="Filter by a date and time range"
                        />
                    </FormField>
                </SpaceBetween>

            </Form>
        </form>
    </Container>
}